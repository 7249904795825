import styled from 'styled-components'
import { Link } from 'gatsby'

export const HeaderSection = styled.div`
    padding-left: 75px;
    display: flex;
    justify-content: flex-start;
   
    @media (max-width: 1200px) {
        padding-left: 45px;
    }

    @media (max-width: 1024px) {
        padding-left: 20px;
    }
`

export const Logo = styled.img`
    max-height: 100px;
    max-width: none;
    -o-object-fit: fill;
    object-fit: fill;

    @media (max-width: 480px) {
        max-height: 80px;
    }

`

export const NavContainer = styled.ul`
    margin-left: 75px;
 
    font-weight: 700;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-block-end: 0;

    @media (max-width: 1125px) {
        display: none;
    }

    @media (max-width: 1290px) {
        margin-left: 10px;
    }


`
export const NavItem = styled.li`
    color: #444;
    line-height: 16px;
    font-weight: 700;
    margin-bottom: 0;
    margin-block-end: 0;
    margin-right: 30px;


`

export const SocialIconsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    justify-content: flex-end;
    margin-right: 60px;

    @media (max-width: 1125px) {
        display: none;
    }

`

export const SocialIcon = styled.span`
    &:before {
        font-family: 'icomoon';
        speak: none;
        font-weight: normal;
        line-height: 0.8;
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
        float: left;
        vertical-align: middle;
        margin-right: 15px;
        color: #444;
    }

    ${props => props.icon === "facebook" && (`
        &:before {
            content: "\\e604";
        }
    `)}

    ${props => props.icon === "instagram" && (`
        &:before {
            content: "\\e607";
        }
    `)}


`


export const NavLink = styled(Link)`
    text-decoration: none;
    color: #444;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;

    border-bottom: 1px solid !important;
    border-color: transparent !important;
    padding-bottom: 1px !important;

    ${props => props.active && (`
        border-bottom: 4px solid !important;
        border-color: #db002e !important;
        padding-bottom: 1px !important;

    `)}

    


    

    @media (max-width: 1290px) {
        font-size: 14px;
    }

    
    &:after {
        transition: width 0.2s ease, background-color 0.2s ease;
        background: none repeat scroll 0 0 #db002e;
        content: "";
        display: block;
        height: 4px;
        width: 0;
        margin-top: 2px !important;       

    }

    &:hover:after {
        width: 100%;

    }



`

export const Slide = styled.div`
    background-image: url(${props => props.image});
    width: 100%;
    height: 550px;
    background-position:center;
	background-repeat:no-repeat;
    background-size:cover;


    @media (max-width: 474px) {
        height: 350px;

    }        
`

export const SlideText = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;    
    color: white;
    padding-top: 100px;
    flex-direction: column;
`

export const SlideH1 = styled.h1`
    color: white;
    font-size: 80px;
    line-height: 80px;

    @media (max-width: 820px) {
        font-size: 60px;
        line-height: 60px;
    }

    @media (max-width: 610px) {
        font-size: 50px;
        line-height: 50px;
    }

    @media (max-width: 515px) {
        font-size: 40px;
        line-height: 40px;
    }

    @media (max-width: 474px) {
        font-size: 30px;
        line-height: 30px;
    }    



`

export const SlideP = styled.p`
    color: white;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
`
export const SlideButton = styled(Link)`
    margin-top: 30px;
    color: white;
    font-size: 18px;
    padding: 20px;
    background:  transparent;
    height:  50px;
    line-height:  40px;
    border:  5px solid white;
    display:  inline-block;
    text-align:  center;
    width:  150px;
    padding:  0px!important;
    text-decoration: none;



`
