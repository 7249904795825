import styled from 'styled-components'

export const FooterSection = styled.div`
    width: 100%;
    background-color: #222;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
    padding: 40px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 620px) {
        font-size: 13px;
        height: 150px;

    }

    @media (max-width: 378px) {
        font-size: 12px;
        height: 150px;

    }


  
`

export const FooterImage = styled.img`
    margin-right: 20px;
    vertical-align: top;
    width: 129px;
    align-self: flex-start;

    @media (max-width: 620px) {
        width: 80px;
    }



`

export const FooterText = styled.div`
    color: white;
    padding-left: 40px;
    padding-right: 40px;

    @media (max-width: 378px) {
        padding-left: 0;
        padding-right: 0;
        font-size: 9px;           

        a {
            font-size: 10px;
        }
    }


`