import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
        font-family: Montserrat, sans-serif;
        color: #444;
    }
  a {
    color: #db002e;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    text-decoration: underline;      
  }
`

export default GlobalStyle