import React from 'react'
import {FooterSection, FooterImage, FooterText} from '../styles/footer.styled'
import larling from '../images/vi-har-laerling.jpg'
import ansvarsrett from '../images/ansvarsrett.jpg'

const Footer = () => {
    return (
        <FooterSection>
            <FooterImage alt="ansvarsrett" src={ansvarsrett} />
            <FooterImage alt="Vi har lærling" src={larling} />

            <FooterText>
                Murergutta as <br/>
                Smidsrødveien 171B<br/>
                3140 NØTTERØY<br/>
                <a href="tel:93697895">Tlf: 936 97 895</a>
            </FooterText>


            
        </FooterSection>
    )
}

export default Footer