import React, { useState } from "react"
import MGLogo from '../images/mg_logo.png'
import { SocialIcon, Logo, NavContainer, NavLink, HeaderSection, NavItem, SocialIconsWrapper } from "../styles/header.styled"
import { Link } from "gatsby"
import { StyledBurger, StyledMenu } from "../styles/burger.styled"
import { globalHistory } from "@reach/router"


const Header = () => {

  const [open, setOpen] = useState(false)
  const path = globalHistory.location.pathname

  return (<>

      <HeaderSection>
        <Link to="/"><Logo src={MGLogo} alt="" /></Link>
        <NavContainer>
            <NavItem><NavLink active={path.startsWith("/mur")} to="/mur">Mur</NavLink></NavItem>
            <NavItem><NavLink active={path.startsWith("/flis")} to="/flis">Flis</NavLink></NavItem>
            <NavItem><NavLink active={path.startsWith("/betong")} to="/betong">Betong</NavLink></NavItem>
            <NavItem><NavLink active={path.startsWith("/andre-tjenester")} to="/andre-tjenester">Andre tjenester</NavLink></NavItem>
            <NavItem><NavLink active={path.startsWith("/referanser")} to="/referanser">Referanser</NavLink></NavItem>
            <NavItem><NavLink active={path.startsWith("/om-murergutta")} to="/om-murergutta">Om Murergutta</NavLink></NavItem>
            <NavItem><NavLink active={path.startsWith("/kontakt-oss")} to="/kontakt-oss">Kontakt oss</NavLink></NavItem>
        </NavContainer>
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
          <div />
          <div />
          <div />
        </StyledBurger>
        <StyledMenu open={open}>
            <Link to="/" onClick={() => setOpen(false)}>Hjem</Link>
            <Link onClick={() => setOpen(false)} to="/mur" >Mur</Link>
            <Link onClick={() => setOpen(false)} to="/flis" >Flis</Link>
            <Link onClick={() => setOpen(false)} to="/betong">Betong</Link>
            <Link onClick={() => setOpen(false)} to="/andre-tjenester">Andre tjenester</Link>
            <Link onClick={() => setOpen(false)} to="/referanser">Referanser</Link>
            <Link onClick={() => setOpen(false)} to="/om-murergutta">Om Murergutta</Link>
            <Link onClick={() => setOpen(false)} to="/kontakt-oss">Kontakt oss</Link>
        </StyledMenu>
        <SocialIconsWrapper>
          <a href="https://www.facebook.com/murergutta" target="_blank" rel="noreferrer">
            <SocialIcon icon="facebook"/>
          </a>
          <a href="https://www.instagram.com/murergutta" target="_blank" rel="noreferrer">
            <SocialIcon icon="instagram" />
          </a>
        </SocialIconsWrapper>
        


    </HeaderSection>    

  </>)
}


export default Header